const styles = ({ palette, typography }) => ({
  container: {
    listStyle: 'none',
    padding: '0 5px',
    position: 'relative',
  },
  linkText: {
    paddingLeft: typography.pxToRem(8),
  },
  link: {
    border: 'none',
    display: 'flex',
    padding: `${typography.pxToRem(8)} ${typography.pxToRem(2)}`,
    textDecoration: 'none',
    color: palette.text.secondary,
  },
});

export default styles;
